import { defaultErrorMsg, StatusCodes } from '@/utils/constants';
import { messageEndpointUrl } from '../utils';
import { snackbarTypeError, snackbarTypeSuccess, snackbarTypeNotification } from '@/modules/Common/utils/constants';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { buildMkLegacyUri, isMkLegacyUri } from '../utils/uriHelpers';

export const commonActions = {
  displayErrorNotification({ dispatch, rootGetters }, err) {
    if (!isNullOrUndefined(err.response)) {
      const payload = {
        msg: !isStringNullUndefinedOrEmpty(err.response?.data?.message) ? err.response.data.message : defaultErrorMsg,
        type: snackbarTypeError,
      };
      dispatch('common/setNotification', payload, { root: true });
    }
    if (!rootGetters['common/isProduction']) {
      dispatch('common/setErrorLogging', err, { root: true });
    }
  },
  displaySuccessNotification({ dispatch }, message) {
    const payload = {
      msg: message,
      type: snackbarTypeSuccess,
    };

    dispatch('common/setNotification', payload, { root: true });
  },
  displayDefaultNotification({ dispatch }, data) {
    const payload = {
      msg: data.message,
      type: snackbarTypeNotification,
      isClosable: true,
    };

    dispatch('common/setNotification', payload, { root: true });
  },
  setState({ commit }, payload) {
    commit('SET_STATE', payload);
  },
  setNotification({ commit }, payload) {
    commit('SET_NOTIFICATION', payload);
  },
  closeNotification({ commit }) {
    commit('CLOSE_NOTIFICATION');
  },
  setLoadingTranslucent({ commit }, payload) {
    commit('SET_LOADING_TRANSLUCENT', payload);
  },
  isLoading({ commit }, payload) {
    commit('INCREMENT_CALLS_IN_FLIGHT');
    commit('SET_LOADING_TRANSLUCENT', payload.isLoadingTranslucent);

    if (payload.overrideIsLoading !== undefined) {
      commit('SET_OVERRIDE_IS_LOADING', payload.overrideIsLoading);
    }
  },
  decrementCallsInFlight({ commit }) {
    commit('DECREMENT_CALLS_IN_FLIGHT');
  },
  setScrollYPosition({ commit }, payload) {
    commit('SET_SCROLL_Y_POSITION', payload);
  },
  setNavbarEnabled({ commit }, shouldEnable) {
    commit('SET_NAVBAR_FEATURE_FLAG', {
      navbarEnabled: shouldEnable,
    });
  },
  setStep4Enabled({ commit }, shouldEnable) {
    commit('SET_STEP_4_FEATURE_FLAG', {
      step4Enabled: shouldEnable,
    });
  },
  setAssignTriggersToSocialCirclesEnabled({ commit }, isEnabled) {
    commit('SET_ASSIGN_TRIGGERS_TO_SOCIAL_CIRCLE_FLAG', isEnabled);
  },
  setMockNumberOfPalRequests({ commit }, numberOfRequests) {
    commit('SET_MOCK_NUMBER_OF_PAL_REQUESTS', {
      mockNumberOfPalRequests: numberOfRequests,
    });
  },
  setErrorLogging({ commit }, payload) {
    commit('SET_ERROR_LOGGING', payload.stack); // won't work in IE; ff, chrome, safari should be fine
  },
  setVersion({ commit }, version) {
    commit('SET_BUILD_NUMBER', version);
  },
  setModalConfirmationButtonDisabled({ commit }, isDisabled) {
    commit('SET_MODAL_CONFIRMATION_BUTTON_DISABLED', isDisabled);
  },
  setSideMenuEnabled({ commit }, enabled) {
    commit('SET_SIDE_MENU_ENABLED', enabled);
  },
  async getApiHealthStatus({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/health`,
      };

      const response = await mkNetworkRequestWrapper(options);
      commit('SET_API_HEALTH', response.data);
    } catch (err) {
      commit('SET_API_HEALTH', err.response.data);
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getUnreadNotifications({ commit, getters, rootGetters, dispatch }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/User/UnreadNotifications`,
      };

      const response = await mkNetworkRequestWrapper(options);
      const data = response.data.data;
      const notifications = data.notifications.map((item) => {
        if (!isStringNullUndefinedOrEmpty(item.url) && isMkLegacyUri(item.url)) {
          item.url = buildMkLegacyUri(rootGetters['common/originUriLegacy'], item.url);
        }

        return {
          isGroupNotification: item.isGroupNotification,
          message: item.message,
          notificationId: item.notificationId,
          url: item.url,
          typeId: item.notificationTypeId,
          splashHeader: item.splashHeader,
          splashBody: item.splashBody,
        };
      });

      const payload = {
        notifications,
        totalUnreadNotificationCount: data.totalUnreadNotificationCount,
      };

      commit('SET_NOTIFICATIONS', payload);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  addNotification({ commit, dispatch, rootGetters }, notification) {
    //fully qualify uri if it points to old portal
    if (!isStringNullUndefinedOrEmpty(notification.url) && isMkLegacyUri(notification.url)) {
      notification.url = buildMkLegacyUri(rootGetters['common/originUriLegacy'], notification.url);
    }
    dispatch('common/displayDefaultNotification', notification, { root: true });
    commit('common/ADD_NOTIFICATION', notification, { root: true });
  },
  async markAllNotificationsAsRead({ commit, dispatch, getters }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/User/markAllNotificationsAsRead`,
        method: 'POST',
      };

      const response = await mkNetworkRequestWrapper(options);

      if (response.status === StatusCodes.NoContent) {
        commit('SET_NOTIFICATIONS', { notifications: [] });
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  clearNotifications({ commit }) {
    commit('SET_NOTIFICATIONS', { notifications: [] });
  },

  async getUnreadMessages({ commit, getters, rootGetters, dispatch }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/User/UnreadMessages`,
      };

      const response = await mkNetworkRequestWrapper(options);
      const totalUnreadMessagesCount = response.data.data.totalUnreadMessagesCount;
      const messages = response.data.data.messages.map((item) => {
        if (!isStringNullUndefinedOrEmpty(item.messageId)) {
          item.url = buildMkLegacyUri(rootGetters['common/originUriLegacy'], `${messageEndpointUrl}${item.messageId}`);
        }

        return {
          subject: item.subject,
          url: item.url,
        };
      });

      commit('SET_MESSAGES', { messages, totalUnreadMessagesCount });
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  addUnreadMessage({ commit, rootGetters }, message) {
    if (!isStringNullUndefinedOrEmpty(message.messageId)) {
      message.url = buildMkLegacyUri(
        rootGetters['common/originUriLegacy'],
        `${messageEndpointUrl}${message.messageId}`,
      );
    }

    const parsedMessage = {
      subject: message.subject,
      url: message.url,
    };

    commit('ADD_MESSAGE', parsedMessage);
  },
  markMessageAsRead({ commit }, messageId) {
    commit('REMOVE_MESSAGE', messageId);
  },
  async markNotificationAsRead({ commit, getters, dispatch }, { notificationId, isGroupNotification }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/User/markNotificationAsRead/${notificationId}?isGroupNotification=${isGroupNotification}`,
        method: 'POST',
      };

      await mkNetworkRequestWrapper(options);

      commit('REMOVE_NOTIFICATION', notificationId);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  refreshMessages({ commit }, { messages, totalUnreadMessagesCount }) {
    commit('SET_MESSAGES', { messages, totalUnreadMessagesCount });
  },
  refreshNotifications({ commit }, { notifications, totalUnreadNotificationCount }) {
    commit('SET_NOTIFICATIONS', { notifications, totalUnreadNotificationCount });
  },
  setCsrfToken({ commit }, csrfToken) {
    commit('SET_CSRF_TOKEN', csrfToken);
  },
  setOriginUriLegacy({ commit }, originUriLegacy) {
    console.log('originUriLegacy(action)=' + originUriLegacy);
    commit('SET_ORIGIN_URI_LEGACY', originUriLegacy);
  },
  async getFusionChartsKey({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.commonApiBaseUrl}/setting/fusioncharts-key`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);

      commit('SET_FUSIONCHARTS_KEY', response.data);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
