export const createNewMenuButtonText = 'Create New Menu';
export const createNewMenuText = 'Create New Menu';
export const adminMenuManagementSearchFieldText = 'Menu Name';
export const selectYourMenuDesignMethodText = 'Design';
export const createFromScratchText = 'Create from scratch';
export const createFromExistingMenu = 'Create from existing menu';
export const createMenuStepTwoTitleText = 'Select Groups';
export const menuNameText = 'Menu Name';
export const nameNotUniqueErrorMessage = 'A menu with this name already exists.';
export const menuItemNameNotUniqueErrorMessage = 'A menu item with this name already exists.';
export const cancelModalText = 'Your progress will not be saved. Would you like to proceed?';
export const cancelModalTitleText = 'Discard Menu?';
export const defaultSelectMenuText = '--Select Menu--';
export const createMenuStepThreeTitleText = 'Menu Details';
export const createMenuStepThreeHeaderDetailsText =
  'Upon login, users will land on the page associated with the first menu item. Note: You must build menu items in the order in which you want them to appear. You are not allowed to adjust the sort order.';
export const addMenuItemButtonText = 'Add menu item';
export const createMenuStepFourTitleText = 'Launch';
export const singleMenuItemText = 'Single Menu Item';
export const parentMenuItemText = 'Parent Menu Item';
export const childMenuItemText = 'Child Menu Item';
export const homePageText = 'Homepage';
export const menuItemDetailsText = 'Menu Item Details';
export const editStepOneTitle = 'Edit Menu Name';
export const editSelectedGroups = 'Edit Selected Groups';
export const selectedGroupsText = 'Selected Groups';
export const editMenuDetails = 'Edit Menu Details';
export const editMenuStatus = 'Edit Menu Status';
export const editMenuStepTwoSubHeaderText = 'Add/Edit Menu Items';
export const editMenuStepTwoHeaderDetailsText =
  'Select menu items to edit or delete or drag and drop to adjust the sort order.';
export const editMenuText = 'Edit Menu';
export const menuItemNameText = 'Menu Item Item';
export const standardComponentText = 'Standard Components';
export const thirdPartyLinkText = '3rd Party Link';
export const customUrlText = 'Custom URL';
export const detailsText = 'Details';
export const discardMenuItemModalTitleText = 'Discard Menu Item';
export const discardMenuItemModalText = 'Your menu item will be deleted. Would you like to proceed?';
export const createChildMenuItemSelectText = 'Parent Menu Item';
export const noMenuSearchResultsText = 'There are no menus that match your search criteria.';
export const deleteMenuText =
  'Deleting this menu will cause all groups assigned to this menu to be reassigned to the standard system default menu. Do you wish to continue?';
export const deleteMenuBtnText = 'Continue';
export const deleteMenuTitleText = 'Delete Menu';
export const searchDropdownText = 'Status';
export const openLinkInNewTabText = 'Open link in a new tab';
export const editMenuSubHeaderText = 'Note: Once your menu is saved, you are not able to edit the assigned groups.';
export const viewGroupsEditSubHeader = 'View Assigned Groups';
export const viewGroupsEditText =
  'The groups assigned to this menu are shown below. Changes to group selections are not allowed.';
export const groupNameText = 'Group Name';
export const draftMenuToolTipText = 'This menu is in draft status. Once launched, it will be visible to these groups.';
export const discardChangesModalText = 'Your progress will not be saved. Would you like to proceed?';
export const editMenuGroupLongLoad = 'Please be patient while groups are loading...';
export const menuCannotBeEditedOrDeletedText = 'This menu cannot be edited or deleted.';
export const groupNotFoundText = 'Group Not Found';
export const menuDeletedSuccessfullyText = (menuName) => `Menu "${menuName}" has been deleted`;
export const discardUnsavedMenuItemModalText =
  'Your menu item has not been saved and changes will be lost if you continue. Are you sure you want to proceed?';
export const IntroText =
  'Menu Management allows you to create and manage the slide-out menu for your member portal groups. With it, you can build menus that drive user behavior & provide a highly tailored user experience.';
export const triggerTooltip = 'This menu item is only displayed to members who meet the triggered criteria.';
export const notEnabledForAllGroupsTooltip =
  'This menu item will only display to members in groups where the linked Standard Component or 3rd Party Link is enabled.';
