import { isNullOrUndefined, isStringNullUndefinedOrWhitespace } from '@/utils/helperFunctions';
import { adminMenuNameMaxLength } from '@/modules/AdminMenuManagement/utils';

export const adminMenuCreationRules = {
  name: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Menu Name is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= adminMenuNameMaxLength) ||
      `Menu name must be less than or equal to ${adminMenuNameMaxLength} characters.`,
    (v) => v?.trim().toLowerCase() !== 'default' || `"Default" is a reserved name and cannot be used for custom menus.`,
  ],
};

export const adminMenuItemRules = {
  name: [
    (v) => !isStringNullUndefinedOrWhitespace(v) || `Menu Item Name is required.`,
    (v) =>
      (!isNullOrUndefined(v) && v.length <= adminMenuNameMaxLength) ||
      `Menu Item Names must be ${adminMenuNameMaxLength} characters or less.`,
  ],
};

export const customUrlCreationRules = {
  name: [(v) => !isStringNullUndefinedOrWhitespace(v) || `Custom URL is required.`],
};
