import {
  adminMenuNameMaxLength,
  menuItemComponentTypes,
  menuItemResourceTypes,
  menuItemSources,
} from '@/modules/AdminMenuManagement/utils';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '@/utils/helperFunctions';

const adminMenuManagementSearchGetters = {
  menus: (state) => state.search.allMenus,
  searchResults: (state) => state.search.searchResults,
  searchParams: (state) => state.search.searchParams,
  paginationInfo: (state) => state.search.paginationInfo,
  totalSearchResultsCount: (state) => state.search.totalCount,
  getIsSearching: (state) => state.search.searching,
};

export const adminMenuManagementGetters = {
  ...adminMenuManagementSearchGetters,
  getShowTree: (state) => state.showTree,
  adminMenuManagementApiBaseUrl: (state, getters, rootState) => rootState.common.apiUrl,
  createMenuName: (state) => state.createNewMenu.menuName,
  currentStep: (state) => state.currentStep,
  createNewMenuDesignMethod: (state) => state.createNewMenu.designMethod,
  menuTree: (state) => state.createNewMenu.menuTree,
  availableGroups: (state) => state.availableGroups,
  parentMenuItems: (state) => state.createNewMenu.parentMenuItems,
  selectedExistingMenu: (state) => state.createNewMenu.selectedExistingMenu,
  selectedGroupIds: (state) => state.createNewMenu.selectedGroupIds,
  isMenuItemFormDirty: (state) =>
    !isNullOrUndefined(state.createNewMenu?.selectedMenuItem) || state.createNewMenu.isMenuItemFormDirty,
  isEditMode: (state) => state.isEditMode,
  menuStatus: (state) => state.createNewMenu.status,
  isEditFormDirty: (state) => state.isEditFormDirty,
  predefinedItems: (state) => state.createNewMenu.predefinedItems,
  loadingItems: (state) => state.loadingItems,
  getSelectedMenu: (state) => state.createNewMenu.selectedMenu,
  standardComponents: (state) => {
    if (state.standardComponents.length !== 0) {
      let filteredComponents = state.standardComponents;

      if (state.surveys.length === 0) {
        filteredComponents = filteredComponents.filter((c) => c.componentType !== menuItemComponentTypes.survey);
      }

      if (state.cmsTiles.length === 0) {
        filteredComponents = filteredComponents.filter((c) => c.componentType !== menuItemComponentTypes.tile);
      }

      return filteredComponents;
    }

    return state.standardComponents;
  },
  surveys: (state) => state.surveys,
  thirdPartyLinks: (state) => state.thirdPartyLinks,
  cmsTiles: (state) => state.cmsTiles,
  avidonCourses: (state) => state.avidonCourses,
  avidonFlows: (state) => state.avidonFlows,
  avidonPages: (state) => state.avidonPages,
  selectedMenuItem: (state) => state.createNewMenu.selectedMenuItem,
  lastUsedMenuItemId: (state) => state.createNewMenu.lastUsedMenuItemId,
  isMenuItemNameUnique: (state) => {
    const menuTree = state.createNewMenu.menuTree;
    const selectedMenuItem = state.createNewMenu.selectedMenuItem;

    if (menuTree.length === 0 || !selectedMenuItem) {
      return true;
    }

    const { id, name } = selectedMenuItem;

    function search(menuItems) {
      for (let menuItem of menuItems) {
        if (menuItem.id === id || isNullOrUndefined(menuItem.name)) {
          continue;
        }

        if (menuItem.name?.trim().toLowerCase() === name?.trim().toLowerCase()) {
          return false;
        }

        if (menuItem.children && menuItem.children.length !== 0) {
          return search(menuItem.children);
        }
      }

      return true;
    }

    const result = search(menuTree);
    return result;
  },
  isMenuItemFormValid(state) {
    const menuTree = state.createNewMenu.menuTree;

    if (
      menuTree.length === 0 ||
      menuTree.some(
        (menuItem) =>
          menuItem.errorCode || (menuItem.children.length && menuItem.children.some((child) => child.errorCode)),
      )
    ) {
      return false;
    }

    return true;
  },
  isSelectedMenuItemValid: (state) => {
    if (!state.createNewMenu.selectedMenuItem) {
      return true;
    }

    const {
      name,
      menuItemResource,
      icon,
      isChildMenuItem,
      isSingleMenuItem,
      isParentMenuItem,
      parentMenuItemId,
    } = state.createNewMenu.selectedMenuItem;

    const { standardComponent, thirdPartyLink } = menuItemResource;

    if (
      isStringNullUndefinedOrEmpty(name?.trim()) ||
      name.trim().length > adminMenuNameMaxLength ||
      state.createNewMenu.validationErrors.length !== 0
    ) {
      return false;
    }

    if ((isParentMenuItem || isSingleMenuItem) && isStringNullUndefinedOrEmpty(icon)) {
      return false;
    }

    // Parent only requires unique name and icon
    if (isParentMenuItem) {
      return true;
    }

    // Child Menu Item Validation
    if (isChildMenuItem) {
      if (isNullOrUndefined(parentMenuItemId) || parentMenuItemId === -1) {
        return false;
      }
    }

    // standard component, third party link, custom url
    if (!menuItemResource || isNullOrUndefined(menuItemResource?.menuItemResourceType)) {
      return false;
    }

    let selectedComponent = null;

    switch (menuItemResource.menuItemResourceType) {
      // Standard Component Validation
      case menuItemResourceTypes.standardComponent:
        if (isNullOrUndefined(standardComponent)) {
          return false;
        }

        selectedComponent = standardComponent;

        if (selectedComponent.source === menuItemSources.dynamic) {
          if (isNullOrUndefined(menuItemResource.componentId)) {
            return false;
          }

          if (
            selectedComponent.componentType === menuItemComponentTypes.survey &&
            !state.surveys.find((s) => s.id === menuItemResource.componentId)
          ) {
            return false;
          } else if (
            selectedComponent.componentType === menuItemComponentTypes.tile &&
            !state.cmsTiles.find((t) => t.id === menuItemResource.componentId)
          ) {
            return false;
          }
        }
        break;
      // Third Party Validation
      case menuItemResourceTypes.thirdPartyLink:
        if (isNullOrUndefined(thirdPartyLink)) {
          return false;
        }

        selectedComponent = thirdPartyLink;
        if (selectedComponent.source === menuItemSources.dynamic) {
          if (isNullOrUndefined(menuItemResource.componentId)) {
            return false;
          }

          if (
            selectedComponent.componentType === menuItemComponentTypes.avidonCourse &&
            !state.avidonCourses.find((course) => course.id.toString() === menuItemResource.componentId.toString())
          ) {
            return false;
          } else if (
            selectedComponent.componentType === menuItemComponentTypes.avidonPage &&
            !state.avidonPages.find((page) => page.id.toString() === menuItemResource.componentId.toString())
          ) {
            return false;
          } else if (
            selectedComponent.componentType === menuItemComponentTypes.avidonFlow &&
            !state.avidonFlows.find((flow) => flow.id.toString() === menuItemResource.componentId.toString())
          ) {
            return false;
          }
        }
        break;
      case menuItemResourceTypes.customUrl:
        if (isStringNullUndefinedOrEmpty(menuItemResource.url?.trim())) {
          return false;
        }
        break;
    }

    if (
      !isNullOrUndefined(selectedComponent) &&
      selectedComponent.source === menuItemSources.predefined &&
      selectedComponent.items.length !== 0 &&
      isNullOrUndefined(menuItemResource.componentId)
    ) {
      return false;
    }

    return true;
  },
  validationErrors: (state) => state.createNewMenu.validationErrors,
  assignedGroupIds: (state) => state.createNewMenu.assignedGroupIds,
};
