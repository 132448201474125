export const AdminMenuManagement = {
  name: 'AdminMenuManagementSearch',
  path: '/admin-menu-management/search',
};

export const CreateNewMenuWizard = {
  name: 'AdminMenuManagementCreateNewMenuWizard',
  path: '/admin-menu-management/create-new-menu',
};

export const EditMenuWizard = {
  name: 'AdminMenuManagementEditMenuWizard',
  path: '/admin-menu-management/edit/:menuId',
};

const adminMenuManagementRoutes = [
  {
    path: AdminMenuManagement.path,
    name: AdminMenuManagement.name,
    props: true,
    component: () => import('@/modules/AdminMenuManagement/components/search/AdminMenuManagementSearch'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: CreateNewMenuWizard.path,
    name: CreateNewMenuWizard.name,
    props: true,
    component: () => import('@/modules/AdminMenuManagement/components/EditMenuWizard/Wizard'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: EditMenuWizard.path,
    name: EditMenuWizard.name,
    props: true,
    component: () => import('@/modules/AdminMenuManagement/components/EditMenuWizard/Wizard'),
    meta: {
      requiresAdmin: true,
    },
  },
];

export default adminMenuManagementRoutes;
