import {
  findAndRemoveMenuItem,
  menuItemComponentTypes,
  menuManagementBaseUrl,
  nameNotUniqueErrorCode,
  initialMenuItemResource,
  menuItemTypes,
  menuItemResourceTypes,
  menuItemSources,
  menuItemErrorCodes,
} from '@/modules/AdminMenuManagement/utils';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { isNullOrUndefined } from '@/utils/helperFunctions';

export const menuItemActions = {
  setMenuItemFormDirty({ commit }, isDirty) {
    commit('SET_MENU_ITEM_FORM_DIRTY', isDirty);
  },
  setSelectedMenuItem({ commit, getters }, payload) {
    const menuItem = payload;
    if (menuItem?.menuItemResource?.menuItemResourceType === menuItemResourceTypes.standardComponent) {
      const standardComponent = getters.standardComponents.find(
        (sc) => sc.id === menuItem.menuItemResource?.standardComponent?.id,
      );

      if (standardComponent?.source === menuItemSources.predefined) {
        menuItem.menuItemResource.standardComponent = standardComponent;
        commit('SET_PREDEFINED_ITEMS', standardComponent.items);
      }
    }

    commit('SET_SELECTED_MENU_ITEM', payload);
  },
  addMenuItem({ commit, getters }, payload) {
    if (payload && payload.fixed) {
      payload.children = [];
      payload.id = getters.lastUsedMenuItemId + 1;

      commit('ADD_MENU_ITEM', payload);
      commit('SET_LAST_USED_MENU_ITEM_ID', payload.id);
      return;
    }

    const menuItem = {
      ...payload,
      children: [],
      icon: '',
      id: getters.lastUsedMenuItemId + 1,
      menuItemType: '',
      isParentMenuItem: false,
      isChildMenuItem: false,
      isSingleMenuItem: false,
      menuItemResource: { ...initialMenuItemResource },
      name: '',
      selectable: true,
      parentMenuItemId: null,
      draft: true,
    };
    commit('ADD_MENU_ITEM', { ...menuItem });
    commit('SET_LAST_USED_MENU_ITEM_ID', menuItem.id);
    commit('SET_SELECTED_MENU_ITEM', menuItem);
  },
  resetMenuItemErrorCode({ commit, getters }) {
    commit('SET_MENU_ITEM_ERROR_CODE', { selectedMenuItem: getters.selectedMenuItem, errorCode: null });
  },
  resetMenuItems({ commit }) {
    commit('UPDATE_MENU_TREE', []);
    commit('SET_PARENT_MENU_ITEMS', []);
  },
  resetGroupTree({ commit }) {
    commit('SET_ASSIGNED_GROUP_IDS', []);
    commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', []);
  },
  setMenuItems({ commit, getters }) {
    function mapMenuItem(item) {
      const menuItem = {
        // Child Menu Items
        icon: item.icon,
        isChildMenuItem: item.menuItemType === menuItemTypes.childMenuItem ?? false,
        isParentMenuItem: item.menuItemType === menuItemTypes.parentMenuItem ?? false,
        isSingleMenuItem: item.menuItemType === menuItemTypes.singleMenuItem ?? false,
        id: item.menuItemId,
        menuItemResource: { ...item.menuItemDetails },
        menuItemType: item.menuItemType,
        name: item.name,
        selectable: true,
        parentMenuItemId: item.parentMenuItemId ?? null,
        errorCode: item.hasError ? 'DISABLE_DELETED_LINKED_ITEM' : null,
        draft: false,
        triggers: [...item.triggers],
      };
      menuItem.menuItemResource.thirdPartyLink = menuItem.menuItemResource.standardComponent;

      menuItem.children = item.childMenuItems.map((child) => {
        const childMenuItem = mapMenuItem(child);
        return childMenuItem;
      });

      return menuItem;
    }
    const menuItems = getters.getSelectedMenu.menuItems;
    const mappedMenuItems = menuItems.map((item) => {
      const menuItem = mapMenuItem(item);
      return menuItem;
    });
    commit(
      'SET_PARENT_MENU_ITEMS',
      mappedMenuItems.filter((mi) => mi.isParentMenuItem),
    );
    commit('UPDATE_MENU_TREE', mappedMenuItems);
  },
  validateMenuItems({ commit, getters }) {
    const menuTree = getters.menuTree;
    if (!menuTree.length) {
      return;
    }
    function validateLinkedItem(menuItem) {
      if (menuItem.menuItemResource.menuItemResourceType === menuItemResourceTypes.standardComponent) {
        const standardComponent = getters.standardComponents.find(
          (sc) => sc.id === menuItem.menuItemResource?.standardComponent?.id,
        );

        if (!standardComponent) {
          menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
          return;
        }
        menuItem.menuItemResource.standardComponent = standardComponent;
        delete menuItem.enabledForAllGroups;
        if (
          standardComponent.source === menuItemSources.predefined &&
          standardComponent.items.length > 0 &&
          !standardComponent.items.find(
            (item) => item.id.toString() === menuItem.menuItemResource?.componentId.toString(),
          )
        ) {
          menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
          return;
        }
        if (standardComponent.source === menuItemSources.dynamic) {
          switch (menuItem.menuItemResource?.standardComponent?.componentType) {
            case menuItemComponentTypes.survey:
              {
                let survey = getters.surveys.find((s) => s.id === menuItem.menuItemResource?.componentId);
                if (!survey) {
                  menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
                } else {
                  menuItem.errorCode = null;
                  menuItem.enabledForAllGroups = survey.enabledForAllGroups;
                }
              }
              break;
            case menuItemComponentTypes.tile:
              {
                let tile = getters.cmsTiles.find((c) => c.id === menuItem.menuItemResource?.componentId);
                if (!tile) {
                  menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
                } else {
                  menuItem.errorCode = null;
                  menuItem.enabledForAllGroups = tile.enabledForAllGroups;
                }
              }
              break;
            default:
              menuItem.errorCode = null;
              break;
          }
        } else {
          menuItem.errorCode = null;
        }
      } else if (menuItem.menuItemResource.menuItemResourceType === menuItemResourceTypes.thirdPartyLink) {
        const thirdPartyLink = getters.thirdPartyLinks.find(
          (tpl) => tpl.id === menuItem.menuItemResource?.thirdPartyLink?.id,
        );
        if (!thirdPartyLink) {
          menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
          return;
        }
        menuItem.menuItemResource.standardComponent = thirdPartyLink;
        menuItem.menuItemResource.thirdPartyLink = thirdPartyLink;
        delete menuItem.enabledForAllGroups;

        switch (menuItem.menuItemResource?.thirdPartyLink?.componentType) {
          case menuItemComponentTypes.avidonPage:
            {
              let page = getters.avidonPages.find((p) => p.id === menuItem.menuItemResource?.componentId);
              if (!page) {
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
                menuItem.enabledForAllGroups = page.enabledForAllGroups;
              }
            }
            break;
          case menuItemComponentTypes.avidonCourse:
            {
              let course = getters.avidonCourses.find((s) => s.id === menuItem.menuItemResource?.componentId);
              if (!course) {
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
                menuItem.enabledForAllGroups = course.enabledForAllGroups;
              }
            }
            break;
          case menuItemComponentTypes.avidonFlow:
            {
              let flow = getters.avidonFlows.find((c) => c.id === menuItem.menuItemResource?.componentId);
              if (!flow) {
                menuItem.errorCode = menuItemErrorCodes.invalidLinkedItem;
              } else {
                menuItem.errorCode = null;
                menuItem.enabledForAllGroups = flow.enabledForAllGroups;
              }
            }
            break;

          default:
            menuItem.errorCode = null;
            break;
        }
      }
    }

    for (let i = 0; i < menuTree.length; i++) {
      let item = menuTree[i];
      validateLinkedItem(item);

      if (item.children.length) {
        for (let j = 0; j < item.children.length; j++) {
          let child = item.children[j];
          validateLinkedItem(child);
        }
      }
    }

    commit('UPDATE_MENU_TREE', menuTree);
  },
  saveMenuItem({ commit, dispatch, getters }, triggers) {
    const selectedMenuItem = getters.selectedMenuItem;
    const updatedMenuTree = [];

    function updateMenuItems(menuItems, targetId) {
      for (let i = menuItems.length - 1; i >= 0; i--) {
        let menuItem = menuItems[i];
        if (menuItem.id === targetId) {
          menuItem = { ...selectedMenuItem, draft: false, triggers: triggers };
          menuItem.errorCode = null;

          if (!isNullOrUndefined(selectedMenuItem.parentMenuItemId) && selectedMenuItem.parentMenuItemId !== -1) {
            const selectedParent = getters.parentMenuItems.find((pmi) => pmi.id === selectedMenuItem.parentMenuItemId);
            if (selectedParent) {
              selectedParent.children.push(menuItem);
            }
          } else {
            updatedMenuTree.unshift(menuItem);

            if (menuItem.isParentMenuItem) {
              dispatch('updateParentMenuItems');
            }
          }

          if (menuItem.children.length) {
            updateMenuItems(menuItem.children, targetId);
          }
        } else if (menuItem.children?.length) {
          let childMenuItem = menuItem.children.find((mi) => mi.id === targetId);

          if (childMenuItem) {
            Object.assign(childMenuItem, { ...selectedMenuItem, draft: false, errorCode: null, triggers: triggers });
          }

          updatedMenuTree.unshift(menuItem);
        } else if (isNullOrUndefined(menuItem.parentMenuItemId) || menuItem.parentMenuItemId === -1) {
          updatedMenuTree.unshift(menuItem);
        }
      }
    }

    updateMenuItems(getters.menuTree, selectedMenuItem.id);

    commit('UPDATE_MENU_TREE', updatedMenuTree);
  },
  setMenuItemName({ commit, dispatch, getters }, menuItemName) {
    commit('SET_MENU_ITEM_NAME', menuItemName?.trimStart());

    if (!getters.isMenuItemNameUnique) {
      dispatch('setValidationErrors', [{ errorCode: nameNotUniqueErrorCode }]);
    } else {
      dispatch('setValidationErrors', []);
    }
  },
  setOpenLinkInNewTab({ commit }, openLinkInNewTab) {
    commit('SET_OPEN_LINK_IN_NEW_TAB', openLinkInNewTab);
  },
  removeMenuItem({ dispatch, getters }) {
    const menuItemToDeleteId = getters.selectedMenuItem.id;
    const menuTree = getters.menuTree;

    const result = findAndRemoveMenuItem(menuTree, menuItemToDeleteId);
    if (result) {
      dispatch('updateParentMenuItems', true);
    }
  },
  updateParentMenuItems({ commit, getters }, remove) {
    const { id, menuItemType } = getters.selectedMenuItem;
    let parentMenuItems = getters.parentMenuItems;

    if (!remove && menuItemType === menuItemTypes.parentMenuItem && !parentMenuItems.find((pmi) => pmi.id === id)) {
      parentMenuItems.push(getters.selectedMenuItem);
    } else if (remove) {
      parentMenuItems = parentMenuItems.filter((pmi) => pmi.id !== id);
    }

    commit('SET_PARENT_MENU_ITEMS', parentMenuItems);
  },
  async loadDynamicData({ commit, getters, dispatch }, selectedGroupIds) {
    function mapEnabledForAllGroups(data) {
      return data.map((item) => {
        if (!item.enabledForAllGroups) {
          item.name += ' *';
        }
        if (item.items) {
          mapEnabledForAllGroups(item.items);
        }
        return item;
      });
    }

    try {
      const options = {
        url: `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/dynamic-data`,
        method: 'POST',
        data: {
          groupIds: selectedGroupIds,
        },
      };
      const response = await mkNetworkRequestWrapper(options);
      const responseData = response.data.data;

      commit('SET_SURVEYS', mapEnabledForAllGroups(responseData.surveys));
      commit('SET_CMS_TILES', mapEnabledForAllGroups(responseData.cmsTiles));
      commit('SET_STANDARD_COMPONENTS', mapEnabledForAllGroups(responseData.standardComponents));
      commit('SET_THIRD_PARTY_LINKS', mapEnabledForAllGroups(responseData.thirdPartyLinks));

      commit('SET_AVIDON_COURSES', mapEnabledForAllGroups(responseData.avidonCourses));
      commit('SET_AVIDON_FLOWS', mapEnabledForAllGroups(responseData.avidonFlows));
      commit('SET_AVIDON_PAGES', mapEnabledForAllGroups(responseData.avidonPages));
    } catch (e) {
      if (e.response.status === 403) {
        commit('SET_SURVEYS', []);
        commit('SET_CMS_TILES', []);
        commit('SET_STANDARD_COMPONENTS', []);
        commit('SET_THIRD_PARTY_LINKS', []);
      } else {
        dispatch('common/displayErrorNotification', e, { root: true });
      }
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
