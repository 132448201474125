import mkNetworkRequestWrapper from '@/utils/requestWrapper';
import { menuManagementBaseUrl } from '@/modules/AdminMenuManagement/utils';

export const menuGroupActions = {
  setSelectedGroups({ commit }, selectedGroupIds) {
    commit('SET_MENU_MANAGEMENT_SELECTED_GROUPS', selectedGroupIds);
  },
  async getMenuManagementGroups({ commit, getters, dispatch }) {
    try {
      let url = `${getters.adminMenuManagementApiBaseUrl}${menuManagementBaseUrl}/groups`;

      const response = await mkNetworkRequestWrapper({ url, method: 'GET' });
      const groups = response.data;
      return groups;
      //commit('SET_AVAILABLE_GROUPS', groupTree);
    } catch (e) {
      dispatch('common/displayErrorNotification', e, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  showGroupTree({ commit }, show) {
    commit('SET_SHOW_GROUP_TREE', show);
  },
};
