// endpoints

export const refreshEndpoint = '/ajax/token-refresh.aspx';

// legacy

export const accessToken = 'access_token';
export const profile = 'profile';
export const accessTokenFromLegacy = 'accessTokenFromLegacy';
export const CSRFToken = 'CSRFToken';
export const contactId = 'contactId';
export const adminBiometricsBaseUrl = 'components/admin-biometrics/ajax.aspx';

// verbiage

export const defaultSuccessMsg = 'Your request has been completed successfully.';
export const defaultErrorMsg = 'An error has occurred. Please try again later.';
export const dateFormat = 'MM/dd/yyyy';
export const timestampFormat = 'MM/dd/yyyy t';

// types

export const epochStart = new Date('1970-1-1');

// config
export const adminRoutes = ['triggers-admin', 'social-circles-admin', 'menu-management'];

// Status Codes
export const StatusCodes = {
  NoContent: 204,
};

// SignalR Methods
export const signalRMethods = {
  clearNotifications: 'clearNotifications',
  showNotification: 'showNotification',
  messageSent: 'messageSent',
  messageRead: 'messageRead',
  refreshNotifications: 'refreshNotifications',
  refreshMessages: 'refreshMessages',
};
