import { initialMenuItemResource, menuItemSources } from '@/modules/AdminMenuManagement/utils';
import { adminMenuManagementSearchActions } from '@/modules/AdminMenuManagement/store/actions/menuSearch.actions.js';
import { menuActions } from '@/modules/AdminMenuManagement/store/actions/menu.actions.js';
import { menuItemActions } from '@/modules/AdminMenuManagement/store/actions/menuItem.actions.js';
import { menuGroupActions } from '@/modules/AdminMenuManagement/store/actions/menuGroup.actions.js';

export const adminMenuManagementActions = {
  ...menuItemActions,
  ...menuActions,
  ...adminMenuManagementSearchActions,
  ...menuGroupActions,
  resetMenuItemResource({ commit }) {
    commit('RESET_MENU_ITEM_RESOURCE', { ...initialMenuItemResource });
  },
  setCurrentStep({ commit }, payload) {
    commit('SET_CURRENT_STEP', payload);
  },
  setCreateNewMenuDesignMethod({ commit }, payload) {
    commit('SET_CREATE_NEW_MENU_DESIGN_METHOD', payload);
  },
  setSelectedComponentId({ commit }, componentId) {
    commit('SET_SELECTED_COMPONENT_ID', componentId);
  },
  setMenuItemResourceType({ commit }, payload) {
    commit('SET_MENU_ITEM_RESOURCE_TYPE', payload);
    commit('SET_SELECTED_STANDARD_COMPONENT', null);
    commit('SET_SELECTED_THIRD_PARTY_LINK', null);
  },
  setMenuItemType({ commit }, payload) {
    commit('SET_MENU_ITEM_TYPE', payload);
  },
  setMenuItemIcon({ commit }, payload) {
    commit('SET_MENU_ITEM_ICON', payload);
  },
  setMenuItemResourceUrl({ commit }, payload) {
    commit('SET_MENU_ITEM_RESOURCE_URL', payload);
  },
  setSelectedParentMenuItemId({ commit }, parentMenuItemId) {
    commit('SET_SELECTED_PARENT_MENU_ITEM_ID', parentMenuItemId);
  },
  setValidationErrors({ commit }, validationErrors) {
    commit('SET_VALIDATION_ERRORS', validationErrors);
  },
  setSelectedStandardComponent({ commit }, standardComponent) {
    commit('SET_SELECTED_STANDARD_COMPONENT', standardComponent);

    if (standardComponent.source === menuItemSources.predefined) {
      commit('SET_PREDEFINED_ITEMS', standardComponent.items);
    }
  },
  setSelectedThirdPartyLink({ commit }, thirdPartyLink) {
    commit('SET_SELECTED_THIRD_PARTY_LINK', thirdPartyLink);
  },
};
