import {
  analyticsBasePathUrl,
  userPermissionBasePathUrl,
  apiUserPermissions,
  dashboardBasePathUrl,
  adminInfoBasePathUrl,
  apiAdminRolesAndUsers,
  defaultDashboardEndpointUrl,
  saveDashboardFilter,
} from '@/modules/Analytics/utils/endpoints';
import { PERMISSIONS_CONSTANTS } from '@/modules/Analytics/utils/constants';
import mkNetworkRequestWrapper from '@/utils/requestWrapper';

export const analyticsActions = {
  setDashboardName({ commit }, dashboardName) {
    commit('SET_DASHBOARD_NAME', dashboardName);
  },
  setIsEditMode({ commit }, isEditMode) {
    commit('SET_IS_EDIT_MODE', isEditMode);
  },
  setSelectedDashboard({ commit }, dashboard) {
    commit('SET_SELECTED_DASHBOARD', dashboard);
  },
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  setDashboardPermission({ commit, dispatch }, dashboardPermission) {
    commit('SET_DASHBOARD_PERMISSION', dashboardPermission);
    dispatch('updateDashboardBuilderValid');
  },
  setDashboardSelectedRole({ commit }, roles) {
    commit('SET_DASHBOARD_SELECTED_ROLE', roles);
  },
  setSelectedAdminUsers({ commit, dispatch }, users) {
    commit('SET_SELECTED_ADMIN_USERS', users);
    dispatch('updateAdminUsersToView');
  },
  updateAdminUsersToView({ commit, getters }) {
    let viewUsers = getters.adminUsersMaster;
    let selectedUsers = getters.selectedAdminUsersDashboardBuilder;
    let filteredUsers = viewUsers.filter((user) => !selectedUsers.includes(user.contactId));
    let selectedUsersFiltered = getters.selectedAdminUsersDashboardBuilderToView.filter((contactId) => {
      return !selectedUsers.includes(contactId);
    });

    commit('SET_SELECTED_ADMIN_USERS_TO_VIEW', selectedUsersFiltered);
    commit('SET_ADMIN_USERS', filteredUsers);
    if (selectedUsersFiltered.length === 0) {
      commit('SET_DASHBOARD_ASSIGN_USER_VIEW_ACCESS_VALID', false);
    }
  },
  setSelectedAdminUsersToView({ commit }, users) {
    commit('SET_SELECTED_ADMIN_USERS_TO_VIEW', users);
  },
  resetFiltersContainer({ commit }) {
    commit('SET_SINGLE_DATE_FILTER', 0);
    commit('SET_SINGLE_DATE_FILTER_VALUE_START', '');
    commit('SET_SINGLE_DATE_FILTER_VALUE_END', '');
    commit('SET_FILTERS', {});
  },
  setSingleDateFilter({ commit }, date) {
    commit('SET_SINGLE_DATE_FILTER', date);
  },
  setSingleDateFilterValueStart({ commit }, date) {
    commit('SET_SINGLE_DATE_FILTER_VALUE_START', date);
  },
  setShowDashboard({ commit }, showDashboard) {
    commit('SET_SHOW_DASHBOARD', showDashboard);
  },
  setSingleDateFilterValueEnd({ commit }, date) {
    commit('SET_SINGLE_DATE_FILTER_VALUE_END', date);
  },
  resetDashboardBuilderWizard({ commit }) {
    commit('SET_DASHBOARD_NAME', null);
    commit('SET_DASHBOARD_PERMISSION', 1);
    commit('SET_DASHBOARD_LAYOUTS', { items: [] });
    commit('SET_ADMIN_EDITOR_USERS', []);
    commit('SET_SELECTED_ADMIN_USERS', []);
    commit('SET_SELECTED_ADMIN_USERS_TO_VIEW', []);
    commit('SET_DASHBOARD_SELECTED_ROLE', []);
    commit('SET_DASHBOARD_BUILDER_FORM_VALID', false);
    commit('SET_NAME_VALID', false);
  },
  setDashboardLayout({ commit }, dashboardLayout) {
    commit('SET_DASHBOARD_LAYOUTS', dashboardLayout);
  },
  setNameValid({ commit, dispatch }, valid) {
    commit('SET_NAME_VALID', valid);
    dispatch('updateDashboardBuilderValid');
  },
  setdashboardAssignUserAccessValid({ commit, dispatch }, valid) {
    commit('SET_DASHBOARD_ASSIGN_USER_ACCESS_VALID', valid);
    dispatch('updateDashboardBuilderValid');
  },
  setdashboardAssignRoleAccessValid({ commit, dispatch }, valid) {
    commit('SET_DASHBOARD_ASSIGN_ROLE_ACCESS_VALID', valid);
    dispatch('updateDashboardBuilderValid');
  },
  setDashboardAssignUserViewAccessValid({ commit, dispatch }, valid) {
    commit('SET_DASHBOARD_ASSIGN_USER_VIEW_ACCESS_VALID', valid);
    dispatch('updateDashboardBuilderValid');
  },
  updateDashboardBuilderValid({ commit, getters }) {
    if (getters.dashboardPermission === 1 && getters.permissions.canAssignDashboard) {
      commit('SET_DASHBOARD_BUILDER_FORM_VALID', getters.dashboardNameValid && getters.dashboardAssignUserAccessValid);
    }
    if (getters.dashboardPermission === 1 && !getters.permissions.canAssignDashboard) {
      commit('SET_DASHBOARD_BUILDER_FORM_VALID', getters.dashboardNameValid);
    }
    if (getters.dashboardPermission === 2) {
      commit(
        'SET_DASHBOARD_BUILDER_FORM_VALID',
        getters.dashboardNameValid && getters.dashboardAssignRoleAccessValid && getters.dashboardAssignUserAccessValid,
      );
    }
    if (getters.dashboardPermission === 3) {
      commit(
        'SET_DASHBOARD_BUILDER_FORM_VALID',
        getters.dashboardNameValid &&
          getters.dashboardAssignUserAccessValid &&
          getters.dashboardAssignUserViewAccessValid,
      );
    }
  },
  async fetchWidgetData({ commit, dispatch, getters }, widgetParam) {
    if (!('GroupIds' in getters.filters)) return null;

    let queryString = '';

    if (widgetParam.dateRangeType === 'Single') {
      queryString = `startDate=${getters.filters.SingleDateRange.StartDate}&endDate=${getters.filters.SingleDateRange.EndDate}&`;
    } else if (widgetParam.dateRangeType === 'Comparison') {
      for (let d in getters.filters.CustomFilterRanges) {
        queryString += `startDate=${getters.filters.CustomFilterRanges[d].StartDate}&endDate=${getters.filters.CustomFilterRanges[d].EndDate}&`;
      }
    }

    queryString += `dashboardId=${getters.selectedDashboard.id}`;

    if (getters.filters.GroupIds.length > 0) {
      queryString += '&applyGroupSelection=true';
    } else {
      queryString += '&applyGroupSelection=false';
    }

    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${analyticsBasePathUrl}/${widgetParam.endpoint}?${queryString}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchUserPermissions({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${userPermissionBasePathUrl}/${apiUserPermissions}?permissionId=${PERMISSIONS_CONSTANTS.CanAssignDashboard}&permissionId=${PERMISSIONS_CONSTANTS.CanViewDashboard}&permissionId=${PERMISSIONS_CONSTANTS.CanCreateDashboard}`,
        method: 'GET',
      };
      const getUserPermissions = await mkNetworkRequestWrapper(options);
      commit(
        'SET_USER_PERMISSIONS_CAN_VIEW_DASHBOARD',
        getUserPermissions.data.permissions[PERMISSIONS_CONSTANTS.CanViewDashboard],
      );
      commit(
        'SET_USER_PERMISSIONS_CAN_ASSIGN_DASHBOARD',
        getUserPermissions.data.permissions[PERMISSIONS_CONSTANTS.CanAssignDashboard],
      );
      commit(
        'SET_USER_PERMISSIONS_CAN_CREATE_DASHBOARD',
        getUserPermissions.data.permissions[PERMISSIONS_CONSTANTS.CanCreateDashboard],
      );
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateCreatorOnCreate({ commit, rootGetters, getters, dispatch }) {
    let creatorId = rootGetters['users/currentUserSocialProfile'].contactId;
    let editors = getters.allAdminUsers;
    const user = editors.find((user) => user.contactId === creatorId);
    if (user) {
      if (user.firstName && user.lastName) {
        user.name = `${user.lastName}, ${user.firstName} (${user.username}) (Owner)`;
      } else {
        user.name = `${user.username} (Owner)`;
      }
    }
    commit('SET_ADMIN_EDITOR_USERS', editors);
    commit('SET_SELECTED_ADMIN_USERS', [creatorId]);
    dispatch('updateAdminUsersToView');
  },
  async updateCreatorOnEdit({ commit, getters, dispatch }) {
    let creatorId = getters.selectedDashboard.createdBy;
    let editors = getters.allAdminUsers;
    const user = editors.find((user) => user.contactId === creatorId);
    if (user) {
      if (user.firstName && user.lastName) {
        user.name = `${user.lastName}, ${user.firstName} (${user.username}) (Owner)`;
      } else {
        user.name = `${user.username} (Owner)`;
      }
    }
    commit('SET_ADMIN_EDITOR_USERS', editors);
    dispatch('updateAdminUsersToView');
  },
  async saveDashboard({ commit, dispatch, rootGetters, getters }) {
    try {
      let accessIds = getters.selectedAdminUsersDashboardBuilder;
      if (getters.dashboardPermission === 2) {
        accessIds = getters.selectedAdminRolesDashboardBuilder;
      }
      if (getters.dashboardPermission === 3) {
        accessIds = getters.selectedAdminUsersDashboardBuilderToView;
      }
      const payload = {
        dashboardName: getters.dashboardName,
        accessType: getters.dashboardPermission,
        isDefault: false,
        accessIds: accessIds,
        accessCanWriteIds: getters.selectedAdminUsersDashboardBuilder,
        dashboardLayout: getters.dashboardLayout,
      };

      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${dashboardBasePathUrl}`,
        method: 'POST',
        data: payload,
      };
      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async updateDashboard({ commit, dispatch, rootGetters, getters }) {
    try {
      let accessIds = getters.selectedAdminUsersDashboardBuilder;
      if (getters.dashboardPermission === 2) {
        accessIds = getters.selectedAdminRolesDashboardBuilder;
      }
      if (getters.dashboardPermission === 3) {
        accessIds = getters.selectedAdminUsersDashboardBuilderToView;
      }
      const payload = {
        dashboardName: getters.dashboardName,
        accessType: getters.dashboardPermission,
        isDefault: false,
        accessIds: accessIds,
        accessCanWriteIds: getters.selectedAdminUsersDashboardBuilder,
        dashboardLayout: getters.dashboardLayout,
      };
      const options = {
        url: `${rootGetters['common/commonApiBaseUrl']}${dashboardBasePathUrl}/${getters.selectedDashboard.id}`,
        method: 'PUT',
        data: payload,
      };
      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchAdminRolesAndUsersData({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${adminInfoBasePathUrl}/${apiAdminRolesAndUsers}`,
        method: 'GET',
      };
      if (getters.permissions.canAssignDashboard) {
        const rs = await mkNetworkRequestWrapper(options);
        commit('SET_ADMIN_USERS_MASTER', rs.data.analyticsUsers);
        commit('SET_ADMIN_USERS', rs.data.analyticsUsers);
        commit('SET_ADMIN_EDITOR_USERS', rs.data.analyticsEditorUsers);
        commit('SET_ADMIN_ROLES', rs.data.roles);
      }
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },

  async fetchDashboards({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${dashboardBasePathUrl}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      const dashboards = response.data.map((i) => {
        if (i.isDefault) {
          i.dashboardName += ' (default)';
        }
        return i;
      });
      commit('SET_DASHBOARDS', dashboards);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async setDefaultDashboard({ commit, dispatch, getters }, dashboardId) {
    try {
      const payload = {
        dashboardId,
      };
      const options = {
        url: `${getters.analyticsApiBaseUrl}${defaultDashboardEndpointUrl}`,
        method: 'PUT',
        data: payload,
      };
      await mkNetworkRequestWrapper(options);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchDashboardLastUpdatedBy({ commit, getters, dispatch }) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${dashboardBasePathUrl}/last-updated`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async getDashboard({ commit, getters, dispatch }, dashboardId) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${dashboardBasePathUrl}/${dashboardId}`,
        method: 'GET',
      };
      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteDashboard({ commit, getters, dispatch }, dashboardId) {
    try {
      const options = {
        url: `${getters.analyticsApiBaseUrl}${dashboardBasePathUrl}/${dashboardId}`,
        method: 'DELETE',
      };
      const response = await mkNetworkRequestWrapper(options);
      commit('SET_SELECTED_DASHBOARD', getters.defaultDashboard);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async saveFilter({ commit, dispatch, getters }, settings) {
    try {
      let endpoint = `${saveDashboardFilter.replace('{dashboardId}', settings.dashboardId)}?updateGroups=${
        settings.updateGroups
      }`;

      const options = {
        url: `${getters.analyticsApiBaseUrl}${endpoint}`,
        method: 'PUT',
        data: getters.filters,
      };
      await mkNetworkRequestWrapper(options);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async fetchFilter({ commit, dispatch, getters }, dashboardId) {
    try {
      let endpoint = `${saveDashboardFilter.replace('{dashboardId}', dashboardId)}`;

      const options = {
        url: `${getters.analyticsApiBaseUrl}${endpoint}`,
        method: 'GET',
      };

      const response = await mkNetworkRequestWrapper(options);
      return response.data;
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
  async deleteFilter({ commit, dispatch, getters }, dashboardId) {
    try {
      let endpoint = `${saveDashboardFilter.replace('{dashboardId}', dashboardId)}`;

      const options = {
        url: `${getters.analyticsApiBaseUrl}${endpoint}`,
        method: 'DELETE',
        data: getters.filters,
      };

      await mkNetworkRequestWrapper(options);
    } catch (err) {
      dispatch('common/displayErrorNotification', err, { root: true });
    } finally {
      commit('common/DECREMENT_CALLS_IN_FLIGHT', null, { root: true });
    }
  },
};
